import $ = require("jquery");
import { LsCallback, LsCallbackFunction, LsCallbackParameters } from "~/Src/Components/Callback/Callback";

interface ILsScriptOptions {
    url: string;
    async?: boolean;
}

interface ILsScriptLoaderOptions extends ILsScriptOptions {
    loadCallback?: LsCallbackFunction;
    loadCallbackParameters?: LsCallbackParameters;
    loadErrorCallback?: LsCallbackFunction;
}

class LsScript {
    protected static _head: HTMLHeadElement;
    protected static get head(): HTMLHeadElement {
        if (LsScript._head === undefined) {
            LsScript._head = document.getElementsByTagName("head")[0];
        }
        return LsScript._head;
    }

    protected url: string;
    protected async = true;
    protected loading = false;
    protected loaded = false;
    protected loadCallbacks: Array<{ loadCallback: LsCallbackFunction, loadCallbackParameters: LsCallbackParameters, loadErrorCallback: LsCallbackFunction }> = [];

    public constructor(options: ILsScriptOptions) {
        this.url = options.url;
        this.async = options.async;
    }

    public load = (loadCallback: LsCallbackFunction, loadCallbackParameters?: LsCallbackParameters, loadErrorCallback?: LsCallbackFunction) => {
        if (!this.loaded) {
            if (loadCallback || loadErrorCallback) {
                this.loadCallbacks.push({ loadCallback, loadCallbackParameters, loadErrorCallback });
            }

            if (!this.loading) {
                const el = document.createElement("script");
                el.src = this.url;
                el.type = "text/javascript";
                el.async = this.async;
                el.onload = this.onLoad;
                el.onerror = this.onError;
                LsScript.head.appendChild(el);
                this.loading = true;
            }
        } else {
            LsCallback.call(loadCallback, loadCallbackParameters);
        }
    }

    protected onLoad = () => {
        this.loaded = true;
        for (const { loadCallback, loadCallbackParameters } of this.loadCallbacks) {
            LsCallback.call(loadCallback, loadCallbackParameters);
        }
    }

    protected onError = () => {
        this.loaded = false;
        for (const { loadCallback, loadCallbackParameters, loadErrorCallback } of this.loadCallbacks) {
            if (loadErrorCallback) {
                LsCallback.call(loadErrorCallback, loadCallbackParameters);
            }

            LsCallback.call(loadCallback, loadCallbackParameters);
        }
    }
}

export class LsScriptLoader {
    protected static scripts: { [src: string]: LsScript } = {};

    public static load(options: ILsScriptLoaderOptions) {
        let script = this.scripts[options.url];
        if (!script) {
            script = new LsScript(options);
            this.scripts[options.url] = script;
        }

        script.load(options.loadCallback, options.loadCallbackParameters, options.loadErrorCallback);
    }
}

export default LsScriptLoader;
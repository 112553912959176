export interface ILsRetryOptions {
    condition: () => boolean;
    success: () => void;
    failure?: () => void;
    maxTries?: number;
    maxTime?: number;
    delayMultiplier?: number; // in milliseconds
}

class LsRetryDefaultOptions {
    public maxTries = 100;
    public maxTime = 1000 * 30;
    public delayMultiplier = 50;
}

export class LsRetry {
    protected static window = window;
    protected options: ILsRetryOptions;

    protected tries = 0;
    protected time = 0;
    protected timeout: ReturnType<typeof window.setTimeout>;

    protected fib1 = 1;
    protected fib2 = 1;
    protected get fibonacci() {
        const current = this.fib1;
        this.fib1 = this.fib2;
        this.fib2 = current + this.fib1;
        return current;
    }

    public constructor(options: ILsRetryOptions) {
        this.options = $.extend(new LsRetryDefaultOptions(), options);
        this.attempt();
    }

    protected attempt = () => {
        if (this.options.condition()) {
            this.options.success();
        } else {
            this.tries++;
            const delay = this.fibonacci * this.options.delayMultiplier;
            this.time += delay; // update before checking max so we don't go over limit
            if ((this.tries < this.options.maxTries) && (this.time < this.options.maxTime)) {
                this.timeout = LsRetry.window.setTimeout(this.attempt, delay);
            } else {
                this.options.failure?.();
            }
        }
    }
}

export default LsRetry;
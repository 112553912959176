import { LsLogger } from "../Logging/Logger";

type ILsUdo = Array<{ [key: string]: any }>;

class LsUdoManagerDefaults {
    static elementId = "ls-udo-init";
    static attributeName = "data-udo-variable-name";
}

export class LsUdoManager {
    protected static _window: Window;
    protected static get window() {
        return LsUdoManager._window || (LsUdoManager._window = window);
    }

    protected static _document: Document;
    protected static get document() {
        return LsUdoManager._document || (LsUdoManager._document = LsUdoManager.window.document);
    }

    protected static _udoElement: HTMLElement;
    protected static get udoElement() {
        LsUdoManager._udoElement = LsUdoManager._udoElement || (LsUdoManager._udoElement = LsUdoManager.document.getElementById(LsUdoManagerDefaults.elementId));
        if (!LsUdoManager._udoElement) {
            LsLogger.log(`udoElement - element [#${LsUdoManagerDefaults.elementId}] not found`, "fatal");
        }

        return LsUdoManager._udoElement;
    }

    protected static _udoVariableName: string;
    protected static get udoVariableName() {
        return LsUdoManager._udoVariableName || (LsUdoManager._udoVariableName = LsUdoManager.udoElement?.getAttribute(LsUdoManagerDefaults.attributeName));
    }

    protected static get udoData(): ILsUdo {
        return LsUdoManager.window[LsUdoManager.udoVariableName] || [];
    }

    public static get(key: string) {
        const udo = LsUdoManager.udoData;

        for (var i = udo.length - 1; i >= 0; i--) {
            if (udo[i]?.[key]) {
                return udo[i][key];
            }
        }
    }

    public static push(data: any) {
        const udo = LsUdoManager.udoData;
        if (Array.isArray(data)) {
            udo.push(...data);
        } else {
            udo.push(data);
        }
    }
}

export default LsUdoManager;
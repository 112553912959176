import $ = require("jquery");

require("jquery-validation");
require("jquery-validation-unobtrusive");

enum LsValidatorsOperator {
    Equals = "equals",
    NotEquals = "notequals",
    StartsWith = "startswith",
    NotStartsWith = "notstartswith",
    LessThan = "lessthan",
    LessThanOrEqual = "lessthanorequal",
    GreaterThan = "greaterthan",
    GreaterThanOrEqual = "greaterthanorequal",
}

export class LsValidators {
    protected static initialized = false;
    protected static isoDate = /^(\d{4})(-)(0[1-9]|1[0-2])(-)(0[1-9]|[12]\d|3[01])$/;
    protected static usDate = /^(0?[1-9]|1[0-2])([\/-])(0?[1-9]|[12]\d|3[01])(\2)(\d{4})$/;

    public constructor() {
        if (!LsValidators.initialized) {
            // by default, jQuery Validation sets required rule on elements with "required" CSS class
            jQuery.validator.addClassRules("required", { required: false });

            this.date();
            this.email();
            this.cardtype();
            this.securitycode();
            this.daterange();
            //this.compare();
            this.requiredif();
            this.zipcodeus();
            this.phonemin();
            this.dangerousvalues();
            this.mandatory();
            this.validateage();

            LsValidators.initialized = true;
        }
    }

    protected validateage = () => {
        $.validator.addMethod("validateage", (value, el, params: { minimumage: number, yearprop: string, monthprop: string, dayprop: string}) => {
            const bdy = Number(this.getOtherValue(el, params.yearprop));
            const bdm = Number(this.getOtherValue(el, params.monthprop));
            const bdd = Number(this.getOtherValue(el, params.dayprop));

            if (bdy !== 0 && bdm !== 0 && bdd !== 0) {
                const now = new Date();
                const y = now.getFullYear() - params.minimumage;
                const m = now.getMonth() + 1;
                const d = now.getDate();

                if (bdy < y) {
                    return true;
                } else if (bdy == y && bdm < m) {
                    return true;
                } else if (bdy == y && bdm == m && bdd <= d) {
                    return true;
                }

                return false;
            }

            return true;
        });
        $.validator.unobtrusive.adapters.add("validateage", ["prop", "value", "minimumage", "yearprop", "monthprop", "dayprop"], options => {
            options.rules.validateage = options.params;
            options.messages.validateage = options.message;
        });
    }

    protected date = () => {
        // override deprecated built-in date validator
        // modified from jQuery Validation Plugin v1.17.0 additional-methods.js
        $.validator.methods.date = function (value: string, el, params) {
            if (this.optional(el)) { // this == validator instance
                return true;
            }

            const inputType = $(el).attr("type");

            let m: number;
            let d: number;
            let y: number;

            if ((inputType === "date") && LsValidators.isoDate.test(value)) {
                [y, m, d] = (value as string).split("-").map(x => parseInt(x, 10));
            } else if (LsValidators.usDate.test(value)) {
                [m, d, y] = (value as string).split(/[\/-]/).map(x => parseInt(x, 10));
            } else {
                return false;
            }

            const date = new Date(Date.UTC(y, m - 1, d, 12, 0, 0, 0));
            const month = date.getUTCMonth();
            const day = date.getUTCDate();
            const year = date.getUTCFullYear();
            if (((month + 1) === m) && (day === d) && (year === y)) {
                return true;
            }

            return false;
        };
    }

    protected email = () => {
        // override built-in email validator when element has a regex validator
        const email = $.validator.methods.email;
        $.validator.methods.email = function (value: string, el: HTMLElement, params) {
            if (el.hasAttribute("data-val-regex")) {
                return true;
            }
            email.call(this, value, el, params); // this == validator instance
        }
    }

    protected cardtype = () => {
        $.validator.addMethod("cardtype", function (value, el, params) {
            if (this.optional(el)) { // this == validator instance
                return true;
            }

            // simplified from jQuery Validation Plugin v1.16.0 additional-methods.js
            value = value.replace(/\D/g, "");
            if (/^3[47]/.test(value)) { // American Express
                return true;
            } else if (/^(6011|62212[6-9]|6221[3-9]|622[2-8]|6229[01]|62292[1-5]|64[4-9]|65)/.test(value)) { // Discover
                return true;
            } else if (/^(5[1-5]|222[1-9]|22[3-9]|2[3-6]|27[01]|2720)/.test(value)) { // Mastercard
                return true;
            } else if (/^4/.test(value)) { // Visa
                return true;
            }
            return false;
        });
        $.validator.unobtrusive.adapters.addBool("cardtype");
    }

    protected securitycode = () => {
        $.validator.addMethod("securitycode", function (value, el, params) {
            if (this.optional(el)) { // this == validator instance
                return true;
            }

            const $el = $(el);
            const $form = $el.closest("form");
            const $cc = $form.find("[data-creditcard]");
            if ($cc.length > 0) {
                switch ($cc.data("cardType")) {
                    case "AmericanExpress":
                        return value.length === 4;
                    case "Discover":
                    case "Mastercard":
                    case "Visa":
                        return value.length === 3;
                }
            }
            return (value.length === 3) || (value.length === 4);
        });
        $.validator.unobtrusive.adapters.addBool("securitycode");
    }

    protected daterange = () => {
        $.validator.addMethod("mindate", function (value, el, params: string | number | { min: string | number }) {
            if (this.optional(el)) { // this == validator instance
                return true;
            }

            const min = ((typeof params === "string") || (typeof params === "number")) ? params : params.min;

            const thisDate = LsValidators.parseDate(value);
            const minDate = LsValidators.parseDate(min);
            if (thisDate && minDate) {
                return thisDate >= minDate;
            }

            return false;
        });

        $.validator.addMethod("maxdate", function (value, el, params: string | number | { max: string | number }) {
            if (this.optional(el)) { // this == validator instance
                return true;
            }

            const max = ((typeof params === "string") || (typeof params === "number")) ? params : params.max;

            const thisDate = LsValidators.parseDate(value);
            const maxDate = LsValidators.parseDate(max);
            if (thisDate && maxDate) {
                return thisDate <= maxDate;
            }

            return false;
        });

        $.validator.addMethod("daterange", function (value, el, params: Array<string | number> | { min: string | number, max: string | number }) {
            if (this.optional(el)) { // this == validator instance
                return true;
            }

            const [min, max] = Array.isArray(params) ? params : [params.min, params.max];

            const thisDate = LsValidators.parseDate(value);
            const minDate = LsValidators.parseDate(min);
            const maxDate = LsValidators.parseDate(max);
            if (thisDate && minDate && maxDate) {
                return (thisDate >= minDate) && (thisDate <= maxDate);
            }

            return false;
        });
        $.validator.unobtrusive.adapters.addMinMax("daterange", "mindate", "maxdate", "daterange");
    }

    protected compare = () => {
        $.validator.addMethod("compare", function (value, el, params: { prop: string, op: LsValidatorsOperator }) {
            if (this.optional(el)) { // this == validator instance
                return true;
            }

            if (value.length === 0) {
                return true;
            }

            const otherValue = this.getOtherValue(el, params.prop);

            return this.compareValues(value, otherValue, params.op);
        });

        $.validator.unobtrusive.adapters.add("compare", ["prop", "op"], options => {
            options.rules.requiredif = options.params;
            options.messages.requiredif = options.message;
        });
    }

    protected requiredif = () => {
        $.validator.addMethod("requiredif", (value: string | Array<string>, el: HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement, params: { prop: string, op: LsValidatorsOperator, value: string }) => {
            if (this.hasValue(el, value)) {
                return true;
            }

            if (!params.op) {
                return true;
            }

            // value is empty, return whether that's valid
            const otherValue = this.getOtherValue(el, params.prop);

            // if params.value is falsy, otherValue must also be falsy
            if (!params.value) {
                return !!otherValue;
            }

            return !this.compareValues(otherValue.toLowerCase(), params.value.toLowerCase(), params.op);
        });
        $.validator.unobtrusive.adapters.add("requiredif", ["prop", "op", "value"], options => {
            options.rules.requiredif = options.params;
            options.messages.requiredif = options.message;
        });
    }

    // copied from jQuery Validation Plugin v1.16.0 additional-methods.js
    // validation rule must be all lowercase because HTML data attributes must be all lowercase
    protected zipcodeus = () => {
        $.validator.addMethod("zipcodeus", function (value, el, params) {
            if (this.optional(el)) { // this == validator instance
                return true;
            }

            return /^\d{5}(-\d{4})?$/.test(value);
        }, "The specified US ZIP Code is invalid");
        $.validator.unobtrusive.adapters.addBool("zipcodeus");
    }

    protected phonemin = () => {
        $.validator.addMethod("phonemin", function (value, el, params: { [key: string]: any }) {
            if (this.optional(el)) { // this == validator instance
                return true;
            }

            let countryCode = params.uscode;
            if (params.prop) {
                const $country = $(`select[name$="${params.prop}"]`);
                if ($country.length > 0) {
                    countryCode = ($country.val() as string).slice(0, 2);
                }
            }

            var minLength = parseInt(countryCode === params.uscode ? params.us : params.int, 10);

            var digits = value.replace(/\D/g, "");
            return digits.length >= minLength;
        }, "Please specify a valid phone number");
        $.validator.unobtrusive.adapters.add("phonemin", ["prop", "uscode", "us", "int"], options => {
            options.rules.phonemin = options.params;
            options.messages.phonemin = options.message;
        });
    }

    // copied from jQuery Unobtrusive Validation v3.2.11 regex validation
    protected dangerousvalues = () => {
        $.validator.addMethod("dangerousvalues", function (value, el, params) {
            if (this.optional(el)) { // this == validator instance
                return true;
            }

            const match = new RegExp(params).exec(value);
            return (match && (match.index === 0) && (match[0].length === value.length));
        });
        $.validator.unobtrusive.adapters.addSingleVal("dangerousvalues", "pattern");
    }

    protected mandatory = () => {
        $.validator.unobtrusive.adapters.addBool("mandatory", "required");
    }

    protected getOtherValue = (el: Element, id: string) => {
        const $form = $(el).closest("form");

        // could match multiple elements because of starts with
        const $other = $form.find(`input[id^="${id}"], textarea[id^="${id}"], select[id^="${id}"], button[id^="${id}"]`);
        if ($other.length === 0) {
            return "";
        }

        const other = $other.get(0) as HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement | HTMLButtonElement;
        switch (other.type) {
            case "checkbox":
                if ((other as HTMLInputElement).checked) {
                    return $other.val() as string;
                } else {
                    const $form = $other.closest("form");
                    const name = $other.attr("name");
                    const $hidden = $form.find(`input[type="hidden"][name="${name}"]`);
                    if ($hidden.length > 0) {
                        return $hidden.val() as string;
                    }
                }
                break;
            case "radio":
                const $checked = $other.filter(":checked");
                if ($checked.length > 0) {
                    return $checked.val() as string;
                }
                break;
            case "select-multiple":
                // TODO
                break;
            default:
                return $other.val() as string;
        }
        return "";
    }

    protected hasValue = (el: HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement, value: string | Array<string>) => {
        switch (el.type) {
            case "checkbox":
                // TODO
                break;
            case "radio":
                if ((el as HTMLInputElement).checked) {
                    return true;
                }
                const $form = $(el).closest("form");
                const $checked = $form.find(`input[type="radio"][name="${el.name}"]:checked`);
                if ($checked.length > 0) {
                    return true;
                }
                break;
            default:
                if (value && (value.length > 0)) {
                    return true;
                }
                break;
        }
        return false;
    }

    protected static parseDate = value => {
        let matches: RegExpExecArray;
        let date: Date;

        if (matches = LsValidators.usDate.exec(String(value))) {
            date = new Date(Date.UTC(parseInt(matches[5], 10), parseInt(matches[1], 10) - 1, parseInt(matches[3]), 12, 0, 0, 0));
        } else if (matches = LsValidators.isoDate.exec(String(value))) {
            date = new Date(Date.UTC(parseInt(matches[1], 10), parseInt(matches[3], 10) - 1, parseInt(matches[5], 10), 12, 0, 0, 0));
        } else {
            date = new Date(value);
        }

        return /Invalid|NaN/.test(date.toString()) ? false : date;
    }

    protected parseNumber = value => {
        return $.isNumeric(value) ? Number(value) : false;
    }

    protected compareValues(value1: any, value2: any, operator: LsValidatorsOperator) {
        switch (operator) {
            case LsValidatorsOperator.Equals:
                return value1 === value2;
            case LsValidatorsOperator.NotEquals:
                return value1 !== value2;
            case LsValidatorsOperator.StartsWith:
                return this.startsWith(String(value1), String(value2));
            case LsValidatorsOperator.NotStartsWith:
                return !this.startsWith(String(value1), String(value2));
            case LsValidatorsOperator.LessThan:
                return this.lessThanOrEqualTo(value1, value2, false);
            case LsValidatorsOperator.LessThanOrEqual:
                return this.lessThanOrEqualTo(value1, value2, true);
            case LsValidatorsOperator.GreaterThan:
                return this.lessThanOrEqualTo(value2, value1, false);
            case LsValidatorsOperator.GreaterThanOrEqual:
                return this.lessThanOrEqualTo(value2, value1, true);
        }
    }

    protected startsWith(value1: string, value2: string) {
        const length = value2.length;
        return value1.slice(0, length) === value2;
    }

    protected lessThanOrEqualTo(value1: any, value2: any, useEquals: boolean) {
        const thisNumber = this.parseNumber(value1);
        const otherNumber = this.parseNumber(value2);
        const thisDate = LsValidators.parseDate(value1);
        const otherDate = LsValidators.parseDate(value2);

        if (thisNumber && otherNumber) {
            if (useEquals) {
                return thisNumber <= otherNumber;
            }

            return thisNumber < otherNumber;
        }

        if (thisDate && otherDate) {
            if (useEquals) {
                return thisDate <= otherDate;
            }

            return thisDate < otherDate;
        }

        return false;
    }
}

export default LsValidators;
import $ = require("jquery");

import LsHint from "~/Src/Components/Hint/Hint";

import "./ShowPassword.scss";

export class LsShowPassword {
    // ReSharper disable InconsistentNaming
    protected static _$document: JQuery<Document>;
    protected static get $document() {
        return LsShowPassword._$document || (LsShowPassword._$document = $(document));
    }
    // ReSharper restore InconsistentNaming

    protected static initialized = false;

    public constructor() {
        const touchPoints = navigator.maxTouchPoints;

        if (!LsShowPassword.initialized) {
            // mousedown so that focus appears to stay in input
            LsShowPassword.$document.on("mousedown", "[data-show-password]", e => {
                e.preventDefault();
            });

            LsShowPassword.$document.on("click", "[data-show-password]", e => {
                e.preventDefault();

                LsHint.hide();

                const $el = $(e.currentTarget);
                const selector: string = $el.data("showPasswordTarget");
                const $input = $(selector);

                if (!$input.prop("disabled")) {

                    $input.trigger("focus");

                    const shown: boolean = $el.data("showPassword");
                    let autocomplete: string = $input.data("autocomplete");
                    if (!autocomplete) {
                        autocomplete = $input.attr("autocomplete");
                        $input.data({ autocomplete });
                    }
                    $el.data("showPassword", !shown);
                    $el.toggleClass("lsc-show-password-hide", shown);
                    $input.attr("autocomplete", shown ? autocomplete : "off");
                    $input.attr("type", shown ? "password" : "text");

                    const eventLabel = `${shown ? "Show" : "Hide"} Password`;
                    $el.data({ eventLabel });
                }
            });

            if (touchPoints === 0) {
                LsShowPassword.$document.on("mouseenter", "[data-show-password]", e => {
                    const $el = $(e.currentTarget);
                    const selector: string = $el.data("showPasswordTarget");
                    const $input = $(selector);

                    if (!$input.prop("disabled")) {
                        const shown = $el.data("showPassword");
                        const title = `${shown ? "Hide" : "Show"} Password`;
                        LsHint.show(e.currentTarget, { title });
                    }
                });

                LsShowPassword.$document.on("mouseleave", "[data-show-password]", LsHint.hide);
            }

            LsShowPassword.initialized = true;
        }
    }
}

export default LsShowPassword;
import $ = require("jquery");

import LsLogger from "~/Src/Components/Logging/Logger";
import { LsRetry, ILsRetryOptions } from "~/Src/Components/Utilities/Retry";

import "./LiveChat.scss";

class LsLiveChatOptions {
    public static livePersonSelector = ".LPMcontainer";
}

export class LsLiveChat {
    protected static initialized = false;
    protected static $livePerson: JQuery;
    protected static retryOptions: ILsRetryOptions;

    public constructor() {
        if (!LsLiveChat.initialized) {
            LsLiveChat.retryOptions = {
                condition: this.getLivePerson,
                success: this.showLivePerson,
                failure: this.logError,
                maxTime: 5000,
            };

            $(document).on("click", '[data-action="livechat"]', e => {
                e.preventDefault();
                new LsRetry(LsLiveChat.retryOptions);
            });

            LsLiveChat.initialized = true;
        }
    }

    protected getLivePerson = () => {
        if (LsLiveChat.$livePerson === undefined) {
            const $lp = $(LsLiveChatOptions.livePersonSelector);
            if ($lp.length > 0) {
                LsLiveChat.$livePerson = $lp;
            }
        }
        return !!LsLiveChat.$livePerson;
    }

    protected showLivePerson = () => {
        LsLiveChat.$livePerson.trigger("click");
    }

    protected logError = () => {
        LsLogger.trace(`LivePerson [${LsLiveChatOptions.livePersonSelector}] not found`);
    }
}

export default LsLiveChat;
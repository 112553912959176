import LsLogger from "../Logging/Logger";
import LsUdoManager from "../Utilities/UdoManager";

export interface ILsEventsData {
    event: string;
    eventCallback?: () => void;
    eventTimeout?: number;
    [key: string]: any;
}

export interface ILsEventsItemsData {
    // ReSharper disable InconsistentNaming
    items: Array<{
        itemid: number;
        price: number;
        quantity: number;
        skuid: number;
        subtotal: number;
        searchalias: string;
        totalquantity: number;
    }>;
    value: number;
    // ReSharper restore InconsistentNaming
}

export class LsEvents {
    // ReSharper disable InconsistentNaming
    protected static _window: Window;
    protected static get window() {
        return LsEvents._window || (LsEvents._window = window);
    }

    protected static _document: Document;
    protected static get document() {
        return LsEvents._document || (LsEvents._document = LsEvents.window.document);
    }
    // ReSharper restore InconsistentNaming

    protected static initialized = false;
    protected browserEventActions = {
        click: "Clicked",
        mouseover: "Hovered",
    }

    public constructor() {
        if (!LsEvents.initialized) {
            LsEvents.document.addEventListener("click", this.handleBrowserEvent);
            LsEvents.document.addEventListener("mouseover", this.handleBrowserEvent);

            if (document.readyState === "loading") {
                document.addEventListener("DOMContentLoaded", this.triggerInitialEvents);
            } else {
                this.triggerInitialEvents();
            }

            LsEvents.initialized = true;
        }
    }

    // this pushes the original object onto the datalayer
    // this does not make a shallow or deep copy
    public static trigger(data: ILsEventsData | Array<ILsEventsData>): void;
    public static trigger(data: unknown) {
        LsUdoManager.push(data);

        if ((window as any).ordergroove) {
            LsEvents.ordergrooveEventHandler(Array.isArray(data) ? data[0] : data);
        }
    }

    public static selectItem(key: unknown, data: unknown, quantity: unknown, module: unknown) {
        if ((typeof key === "string") && LsEvents.isObject(data) && LsEvents.isObject(data.items)) {
            const item = data.items[key];
            if (LsEvents.isObject(item)) {
                const eventData = {
                    event: "select_item",
                    select_item_result: {
                        offeringExternalId: data.OfferingExternalId,
                        id: item.Id,
                        searchAlias: item.SearchAlias,
                        externalId: item.ExternalId,
                        name: item.Name,
                        type: item.Type,
                        listPrice: item.Price.ListPrice,
                        salePrice: item.Price.SalePrice,
                        quantity,
                        subscription: {
                            module,
                        },
                        skus: item.Skus,
                    },
                    ecommerce: {
                        detail: {
                            products: [{
                                name: item["Name"],
                                id: item["SearchAlias"],
                                price: item.Price.SalePrice,
                            }],
                        },
                    },
                    ga_label: {
                        label: item["Name"]
                    }
                };

                LsEvents.trigger(eventData);
            }
        }
    }

    public static setQuantity(selectedItemId: unknown, quantity: unknown, data: unknown, module: unknown) {
        if (data && (typeof data === "object") && (typeof data["items"] === "object")) {
            selectedItemId = Number(selectedItemId);
            for (const k of Object.keys(data["items"])) {
                const item = data["items"][k] as unknown;
                if (typeof item === "object") {
                    const id = Number(item["Id"]);
                    if (id === selectedItemId) {
                        const result = {
                            id,
                            searchAlias: item["SearchAlias"],
                            externalId: item["ExternalId"],
                            name: item["Name"],
                            type: item["Type"],
                            quantity,
                            subscription: {
                                module,
                            },
                        };
                        LsEvents.triggerEventWithResultData("set_quantity", result);
                        break;
                    }
                }
            }
        }
    }

    public static keywordSearch(data: unknown) {
        LsEvents.triggerEventWithResultData("keyword_search", data);
    }

    public static filterItems(data: unknown) {
        LsEvents.triggerEventWithResultData("filter_items", data);
    }

    public static addToWishlist(data: unknown) {
        LsEvents.triggerEventWithResultData("add_to_wishlist", data);
    }

    public static viewQuickShop(data: unknown) {
        LsEvents.triggerEventWithResultData("quick_shop", data);
    }

    public static newsletterSubscription(data: unknown) {
        LsEvents.triggerEventWithResultData("newsletter_subscription", data);
    }

    public static interactionEvent(action: string, category: string, label?: string, value?: number | string) {
        if (!action || !category) {
            return;
        }

        const eventData = {
            event: "interaction_event",
            interaction_event_result: {
                action,
                category,
                label,
                value,
            },
            event_action: action,
            event_category: category,
            event_label: label,
            event_value: value,
        };

        LsEvents.trigger(eventData);
        //LsEvents.triggerEventWithResultData("interaction_event", data);
    }

    protected static triggerEventWithResultData(event: string, data: unknown) {
        const eventData = { event };
        eventData[`${event}_result`] = data;
        LsEvents.trigger(eventData);
    }

    protected handleBrowserEvent = (e: Event) => {
        this.trackInteractionEvent(e);

        if (e.type === "click") {
            this.clickRecommendedProduct(e);
        }
    }

    /**
     * Required:
     * - data-event-action="action type"
     * - data-event-category="category value"
     *
     * Optional:
     * - data-event-label="label"
     * - data-event-value="value"
     */
    protected trackInteractionEvent = (e: Event) => {
        if (e.target instanceof Element) {
            const eventAction = this.browserEventActions[e.type] || e.type;
            const currentTarget = e.target.closest(`[data-event-action~="${eventAction}"]`);
            if (currentTarget instanceof HTMLElement) {
                if ((e instanceof MouseEvent) && (e.relatedTarget instanceof HTMLElement)) {
                    const relatedTarget = e.relatedTarget.closest(`[data-event-action~="${eventAction}"]`);
                    if (relatedTarget === currentTarget) {
                        return;
                    }
                }
                const data = { ...currentTarget.dataset };
                LsEvents.interactionEvent(eventAction, data.eventCategory, data.eventLabel, data.eventValue);
            }
        }
    }

    protected clickRecommendedProduct = (e: Event) => {
        if (e.target instanceof Element) {
            const currentTarget = e.target.closest("[data-event-recommended-product]");
            if (currentTarget instanceof HTMLElement) {
                const data = JSON.parse(currentTarget.dataset.eventRecommendedProduct);
                LsEvents.triggerEventWithResultData("click_recommended_product", data);
            }
        }
    }

    protected triggerInitialEvents = () => {
        const eventDataElements = Array.from(LsEvents.document.querySelectorAll("[data-events-on-load]"));
        if (eventDataElements.length > 0) {
            for (const el of eventDataElements) {
                if (el.textContent) {
                    const data = $(el).data();
                    try {
                        const events = JSON.parse(el.textContent);
                        if (events) {
                            LsEvents.trigger(events);
                        } else {
                            LsLogger.log(`LsEvents.triggerInitialEvents - Event data${data.eventsOnLoad ? ` [${data.eventsOnLoad}]` : ""} is null or empty`, "warn");
                        }
                    } catch (ex) {
                        LsLogger.log(`LsEvents.triggerInitialEvents - Event data${data.eventsOnLoad ? ` [${data.eventsOnLoad}]` : ""} is not valid JSON`, "warn");
                    }
                }
            }
        }
    }

    protected static isObject(obj: unknown): obj is { [key: string]: any } {
        return !Array.isArray(obj) && typeof obj === "object";
    }

    protected static ordergrooveEventHandler = (eventData: any) => {
        switch (eventData.event) {
            case "update_quantity":
                (window as any).ordergroove.UpdateQuantity(eventData.update_quantity_result)
                break;
            case "remove_from_cart":
                (window as any).ordergroove.RemoveFromCart(eventData.remove_from_cart_result)
                break;
            /*case "add_to_cart":
                (window as any).ordergroove.AddToCart(eventData.add_to_cart_result)
                break;
            case "set_quantity":
                (window as any).ordergroove.AddToCart(eventData.set_quantity_result)
                break;*/
            default:
                break
        }
    }
}

export default LsEvents;
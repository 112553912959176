export class LsTextField {
    public static getCaretPosition(el: LsTextFieldElement) {
        if (LsTextField.supportsSelection(el)) {
            return el.selectionStart;
        }
        return el.value.length;
    }

    public static setCaretPosition(el: LsTextFieldElement, position: number) {
        if (("setSelectionRange" in el) && (el.setSelectionRange !== null)) {
            // setting caret position doesn't persist in Android/Chrome (55?)
            // https://github.com/text-mask/text-mask/issues/300
            // https://github.com/igorescobar/jQuery-Mask-Plugin/pull/464
            if (/Android/i.test(window.navigator.userAgent)) {
                window.setTimeout(() => {
                    el.setSelectionRange(position, position);
                }, 0);
            } else {
                el.setSelectionRange(position, position);
            }
        }
    }

    public static isTextField(el: any): el is LsTextFieldElement {
        return (el instanceof HTMLInputElement) || (el instanceof HTMLTextAreaElement);
    }

    public static supportsSelection(el: Element) {
        try {
            return ("selectionStart" in el) && ((el as LsTextFieldElement).selectionStart !== null);
        } catch (ex) {
            return false;
        }
    }
}

export default LsTextField;
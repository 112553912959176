import $ = require("jquery");

import "./ScrollShadows.scss";

class LsScrollShadowsDefaults {
    public static readonly selector = "[data-scroll-shadows]";
    public static readonly defaultClass = "lsc-scrollshadows";
    public static readonly topClass = "lsc-scrollshadows--top";
    public static readonly rightClass = "lsc-scrollshadows--right";
    public static readonly bottomClass = "lsc-scrollshadows--bottom";
    public static readonly leftClass = "lsc-scrollshadows--left";
}

export class LsScrollShadows {
    protected static initialized = false;
    protected static window = window;
    protected static document = document;

    public constructor() {
        if (!LsScrollShadows.initialized) {
            let scrollFrameId: ReturnType<typeof window.requestAnimationFrame>;
            LsScrollShadows.window.addEventListener("scroll", e => {
                LsScrollShadows.window.cancelAnimationFrame(scrollFrameId);
                scrollFrameId = LsScrollShadows.window.requestAnimationFrame(() => this.onScroll(e));
            }, true);

            let resizeFrameId: ReturnType<typeof window.requestAnimationFrame>;
            LsScrollShadows.window.addEventListener("resize", () => {
                LsScrollShadows.window.cancelAnimationFrame(resizeFrameId);
                resizeFrameId = LsScrollShadows.window.requestAnimationFrame(() => LsScrollShadows.toggleAllShadows(LsScrollShadows.document));
            }, true);

            if (document.readyState === "loading") {
                document.addEventListener("DOMContentLoaded", () => this.init(LsScrollShadows.document));
            } else {
                this.init(LsScrollShadows.document);
            }

            $(LsScrollShadows.document).on("modalOpen", "[data-modal]", e => {
                this.init(e.currentTarget);
            });

            LsScrollShadows.initialized = true;
        }
    }

    protected init = (parent: Document | Element) => {
        const elements = Array.from(parent.querySelectorAll<HTMLElement>(LsScrollShadowsDefaults.selector));
        for (const el of elements) {
            el.classList.add(LsScrollShadowsDefaults.defaultClass);
        }
        LsScrollShadows.toggleAllShadows(parent);
    }

    protected onScroll = (e: Event) => {
        if (e.target instanceof Element) {
            const currentTarget = e.target.closest(LsScrollShadowsDefaults.selector);
            if (currentTarget instanceof HTMLElement) {
                LsScrollShadows.toggleShadows(currentTarget);
            }
        }
    }

    public static toggleAllShadows = (parent: Document | Element) => {
        const elements = Array.from(parent.querySelectorAll<HTMLElement>(LsScrollShadowsDefaults.selector));
        elements.map(LsScrollShadows.toggleShadows);
    }

    public static toggleShadows = (el: HTMLElement) => {
        const scrollHeight = el.scrollHeight;
        const offsetHeight = el.offsetHeight;
        const scrollTop = el.scrollTop;
        if (scrollHeight > offsetHeight) {
            if (scrollTop > 0) {
                el.classList.add(LsScrollShadowsDefaults.topClass);
            } else {
                el.classList.remove(LsScrollShadowsDefaults.topClass);
            }
            if (scrollTop < scrollHeight - offsetHeight) {
                el.classList.add(LsScrollShadowsDefaults.bottomClass);
            } else {
                el.classList.remove(LsScrollShadowsDefaults.bottomClass);
            }
        } else {
            el.classList.remove(LsScrollShadowsDefaults.topClass);
            el.classList.remove(LsScrollShadowsDefaults.bottomClass);
        }

        const scrollWidth = el.scrollWidth;
        const offsetWidth = el.offsetWidth;
        const scrollLeft = el.scrollLeft;
        if (scrollWidth > offsetWidth) {
            if (scrollLeft > 0) {
                el.classList.add(LsScrollShadowsDefaults.leftClass);
            } else {
                el.classList.remove(LsScrollShadowsDefaults.leftClass);
            }
            if (scrollLeft < scrollWidth - offsetWidth) {
                el.classList.add(LsScrollShadowsDefaults.rightClass);
            } else {
                el.classList.remove(LsScrollShadowsDefaults.rightClass);
            }
        } else {
            el.classList.remove(LsScrollShadowsDefaults.leftClass);
            el.classList.remove(LsScrollShadowsDefaults.rightClass);
        }
    }
}

export default LsScrollShadows;
import $ = require("jquery");

import LsTextField from "~/Src/Components/TextField/TextField";

interface ILsInputMaskTest {
    test: string | RegExp;
    required: boolean;
}
type LsInputMaskTests = Array<ILsInputMaskTest>;

// convert to string enum with TypeScript 2.4
export class LsInputMasks {
    public static UsPhone = "usphone";
    public static UsDate = "usdate";
    public static CreditCard = "creditcard";
    public static SecurityCode = "securitycode";
    public static GiftCard = "giftcard";
    public static GiftCertificate = "giftcertificate";
    public static GiftCardPin = "giftcardpin";
    public static ZipCode = "zipcode";
}

export class LsInputMask {
    // ReSharper disable InconsistentNaming
    protected static _$document: JQuery<Document>;
    protected static get $document() {
        return LsInputMask._$document || (LsInputMask._$document = $(document));
    }
    // ReSharper restore InconsistentNaming

    protected static initialized = false;
    protected static readonly definitions: { [char: string]: RegExp } = {
        "A": /[A-Za-z]/,
        "9": /[0-9]/,
        "*": /./
    };
    protected static optionalMarker: string = "?";
    protected static masks: { [mask: string]: LsInputMaskTests } = {};

    public constructor() {
        if (!LsInputMask.initialized) {
            // default masks? - NANP/intl phone, cc/visa/mc/amex/discover, US/ISO8601 date
            LsInputMask.masks[LsInputMasks.UsPhone] = LsInputMask.mapTests("(999) 999-9999? ***********************************");
            LsInputMask.masks[LsInputMasks.UsDate] = LsInputMask.mapTests("99/99/9999");
            LsInputMask.masks[LsInputMasks.CreditCard] = LsInputMask.mapTests("9999 9999 9999 9999? 999");
            LsInputMask.masks[LsInputMasks.SecurityCode] = LsInputMask.mapTests("999?9");
            LsInputMask.masks[LsInputMasks.GiftCard] = LsInputMask.mapTests("999999 9999999 99999 9");
            LsInputMask.masks[LsInputMasks.GiftCertificate] = LsInputMask.mapTests("**************************************************");
            LsInputMask.masks[LsInputMasks.GiftCardPin] = LsInputMask.mapTests("99999999");
            LsInputMask.masks[LsInputMasks.ZipCode] = LsInputMask.mapTests("99999?-9999");

            // Chrome and Firefox follow the spec which disallows getting/setting selection in certain types of input elements
            // https://stackoverflow.com/questions/21177489/selectionstart-selectionend-on-input-type-number-no-longer-allowed-in-chrome
            // https://stackoverflow.com/questions/22381837/how-to-overcome-whatwg-w3c-chrome-version-33-0-1750-146-regression-bug-with-i/24247942

            const selector = "[data-mask]";

            LsInputMask.$document.on("keydown", selector, e => {
                const $input = $(e.currentTarget);
                this.setKeyCode($input, e.which);
                this.setBeforeValue($input);
            });

            LsInputMask.$document.on("input", selector, e => {
                if (LsTextField.supportsSelection(e.currentTarget)) {
                    LsInputMask.format(e.currentTarget);
                }
            });

            LsInputMask.$document.on("blur", selector, e => LsInputMask.format(e.currentTarget));

            LsInputMask.$document.on("keyup", selector, e => {
                if (this.isAlphaNumericKey(e.which)) {
                    this.fill(e.currentTarget as Element);
                }
                const $input = $(e.currentTarget);
                this.unsetKeyCode($input);
                this.unsetBeforeValue($input);
            });

            LsInputMask.initialized = true;
        }
    }

    protected fill = (el: Element): void => {
        if (!LsTextField.isTextField(el)) {
            return;
        }

        const $el = $(el);
        const tests = LsInputMask.getTests($el);
        const value = $el.val() as string;
        let fill = "";

        for (let i = value.length; i < tests.length; i++) {
            const { test, required } = tests[i];
            if ((typeof test === "string") && required) {
                fill += test;
            } else {
                break;
            }
        }
        if (`${value}${fill}` !== value) {
            $el.val(`${value}${fill}`);
        }
    }

    public static format = (el: LsJQuerySelector): void => {
        const $el = $(el);

        if (!LsTextField.isTextField(el)) {
            el = $el.get(0);

            if (!LsTextField.isTextField(el)) {
                return;
            }
        }

        const tests = LsInputMask.getTests($el);
        const value = $el.val() as string;

        if ((tests.length > 0) && (value.length > 0) && !LsInputMask.isFormatted(tests, value)) {
            let caretPos = LsTextField.getCaretPosition(el);
            const characters = value.split("");
            let i = 0;

            while (i < characters.length) {
                const test = tests[i] && tests[i].test;
                const char = characters[i];

                if (typeof test === "string") {
                    if (char !== test) {
                        characters.splice(i, 0, test);
                        // Android sometimes sends composition keyCode instead of backspace keyCode, so check whether value is shorter
                        if ((i <= caretPos) && !(LsInputMask.isBackspaceKey(LsInputMask.getKeyCode($el)) || (LsInputMask.isCompositionKey(LsInputMask.getKeyCode($el)) && (value.length < LsInputMask.getBeforeValue($el).length)))) {
                            caretPos++;
                        }
                    }
                    i++;
                } else if (test instanceof RegExp) {
                    if (test.test(char)) {
                        i++;
                    } else {
                        characters.splice(i, 1);
                        if (i < caretPos) {
                            caretPos--;
                        }
                    }
                } else {
                    characters.splice(i, 1);
                }
            }

            const formatted = characters.join("");
            if (formatted !== value) {
                $el.val(formatted);
                if (LsTextField.supportsSelection(el)) {
                    LsTextField.setCaretPosition(el, caretPos);
                }
            }
        }
    }

    protected static getKeyCode = ($input: JQuery): number => {
        return $input.data("inputMaskKeyCode");
    }

    protected setKeyCode = ($input: JQuery, keyCode: number): void => {
        $input.data("inputMaskKeyCode", keyCode);
    }

    protected unsetKeyCode = ($input: JQuery): void => {
        $input.removeData("inputMaskKeyCode");
    }

    protected static getBeforeValue = ($input: JQuery): any => {
        return $input.data("inputMaskBeforeValue");
    }

    protected setBeforeValue = ($input: JQuery): void => {
        $input.data("inputMaskBeforeValue", $input.val());
    }

    protected unsetBeforeValue = ($input: JQuery): void => {
        $input.removeData("inputMaskBeforeValue");
    }

    protected static isBackspaceKey = (keyCode: number): boolean => {
        return keyCode === 8;
    }

    protected static isCompositionKey = (keyCode: number): boolean => {
        return keyCode === 229;
    }

    protected isAlphaNumericKey = (keyCode: number): boolean => {
        return ((keyCode >= 48) && (keyCode <= 57)) || ((keyCode >= 65) && (keyCode <= 90)) || ((keyCode >= 96) && (keyCode <= 105));
    }

    protected static getTests = ($input: JQuery): LsInputMaskTests => {
        const mask = $input.data("mask");
        if (mask && LsInputMask.masks[mask]) {
            return LsInputMask.masks[mask];
        } else if (mask) {
            const tests = LsInputMask.mapTests(mask);
            LsInputMask.masks[mask] = tests;
            return tests;
        } else {
            return [];
        }
    }

    protected static mapTests = (mask: string): LsInputMaskTests => {
        let required = true;
        return $.map(mask.toString().split(""), (char: string): ILsInputMaskTest => {
            if (char === LsInputMask.optionalMarker) {
                required = false;
                return undefined;
            }
            return { test: (LsInputMask.definitions[char] || char), required: required };
        });
    }

    protected static isFormatted = (tests: LsInputMaskTests, value: string): boolean => {
        const len = value.length;
        if (len > tests.length) {
            return false;
        }
        for (let i = 0; i < len; i++) {
            const test = tests[i].test;
            const char = value.charAt(i);
            if (typeof test === "string") {
                if (test !== char) {
                    return false;
                }
            } else if (test instanceof RegExp) {
                if (!test.test(char)) {
                    return false;
                }
            }
        }
        return true;
    }
}

export default LsInputMask;